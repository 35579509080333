import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Button, Table, Input,  Modal, ModalFooter,  Container } from "reactstrap"
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux"
import { getNewCountry, setSelectedPartnerSuccess} from "store/old/partner/actions";
import LoadingSpinner from "./LoaderPartner";
import store from "../../store/index";

const EditPartner = (props) => {


const EditData = props.data;
const EditData_fields = EditData?.message ?? {};
const template = useSelector((state) => state.SourceUpload.templates);
const [selectedTemplate, setTemplate] = useState("")

// const setPartnerName = props.set_partner_name;
// const setPartnerCountry = props.set_partner_country;

const dispatch = useDispatch(); 

const countries = useSelector((state) => state.partner.countries);
const countryData = [
  { id:0,value: "", label: 'Select Template'},
  { id:1,value: '1', label: 'Horisen' },
  { id:2,value: '2', label: 'Alaris' },
]
const defaultCountry = countryData?.filter(obj=>obj.value===EditData_fields?.template_id)
const [initialState,setInitialState] = useState(1)
const [loading,setLoading] = useState(true)

const pageBody = (
<form>
                      <div className="customer-field-container" >
                        <Row>
                          <Col md="4">
                          <label className="lable-customer">Partner Name</label>
                          </Col>
                          <Col md="8">
                          <Input
                            type="name"
                            className="input-operator"
                            placeholder="Enter the Name"
                            defaultValue={EditData_fields?.partner_name}
                            onChange={(e)=>{
                              // setPartnerName(e.target.value);
                              EditData_fields.partner_name=e.target.value;
                            }}
                          />
                          </Col>
                        </Row>
                        <Row>
                        <Col md="4">
                          <label className="lable-customer">Template</label>
                          </Col>
                          <Col md="8">
                          {/* <Select
                            className="basic-single"
                            classNamePrefix="select"
                            isSearchable={true}
                            name="template"
                            options={countryData}
                            placeholder="Template"
                            onChange={e => {
                              setTemplate(e.id)
                            }}
                          /> */}
                          <Select
                            className="customer-mult-input"
                            //isSearchable={true}
                            name="country"
                            options={countryData}
                            defaultValue = {countryData?.filter((obj) =>{
                             if(obj.value === EditData_fields?.template_id){
                              // console.log(true);
                              return obj;
                             }
                            }) }
                            placeholder="Select Template"
                            onChange={(e)=>{
                              EditData_fields.template_id=e.value;
                            }}
                            
                          />
                          </Col>
                        </Row>
                        
                      </div>
                     
                    

                      
                    </form>
)

if(initialState==1){

  let loadPromise = new Promise(async(resolve,reject)=>{
    store.subscribe(()=>{
      const loadSuccess = store.getState();
      const status = loadSuccess.partner.selectedPartnerSuccess
      if(status == true){
        // console.log(status);
        resolve("Done")
      }
    })
  })
  loadPromise.then(async(msg)=>{
    dispatch(setSelectedPartnerSuccess(false))
    setTimeout(()=>{
    setLoading(false)
    },1000)
  })
}

return (
    <React.Fragment>
        {loading ? <LoadingSpinner/>:pageBody}
    </React.Fragment>
  )
}

export default EditPartner

import React, { useState, useMemo, useEffect } from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import BootstrapTable from "react-bootstrap-table-next"
import filterFactory from "react-bootstrap-table2-filter"
import Select from "react-select"
import MetaTags from "react-meta-tags"
import Breadcrumbs from "../Common/Breadcrumb"
// import { DateRangePicker, Stack } from "rsuite"

import Swal from "sweetalert2"
import "toastr/build/toastr.min.css"
import LoadingSpinner from "components/Reports/EditReports/EditTables/loader"
import { useGetCustomerBypassRoutesMutation } from "store/analytics/analytics.api"
import { setBypassRoutesCount } from "store/analytics/analytics.slice"
import { useDispatch, useSelector } from "react-redux"
import { useGetCustomerOperatorCountriesQuery } from "store/country/country.api"
import { setCountries } from "store/country/country.slice"
import {
  useLazyGetCustomerOperatorsByCountryQuery,
} from "store/operator/operator.api"
import { useGetCustomersQuery } from "store/customer/customer.api"
import { setOperators, setGatekeepers } from "store/operator/operator.slice"
import { setCustomers } from "store/customer/customer.slice"
import { get, isEqual } from "lodash"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { DateRangePicker, TagPicker } from "rsuite"
import "rsuite/DateRangePicker/styles/index.css"
import "rsuite/dist/rsuite.min.css"
import { debounce } from "lodash"
import toastr from "toastr"
import SortableHeader from "components/Common/Sort"
import { components } from "react-select"
import { useGetTemplatesQuery } from "store/template/template.api"
import { setTemplates } from "store/template/template.slice"
import {
  selectFilter,
  Comparator,
  textFilter,
} from "react-bootstrap-table2-filter"



const predefinedBottomRanges = [
  {
    label: "Last 3 Months",
    value: [
      new Date(new Date().setMonth(new Date().getMonth() - 3)),
      new Date(),
    ],
  },
  {
    label: "Last 6 Months",
    value: [
      new Date(new Date().setMonth(new Date().getMonth() - 6)),
      new Date(),
    ],
  },
  {
    label: "Last Year",
    value: [
      new Date(new Date().getFullYear() - 1, 0, 1),
      new Date(new Date().getFullYear(), 0, 0),
    ],
  },
  {
    label: "This Year",
    value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
  },
  {
    label: "Q1",
    value: [
      new Date(new Date().getFullYear(), 0, 1),
      new Date(new Date().getFullYear(), 2, 31),
    ],
  },
  {
    label: "Q2",
    value: [
      new Date(new Date().getFullYear(), 3, 1),
      new Date(new Date().getFullYear(), 5, 30),
    ],
  },
  {
    label: "Q3",
    value: [
      new Date(new Date().getFullYear(), 6, 1),
      new Date(new Date().getFullYear(), 8, 30),
    ],
  },
  {
    label: "Q4",
    value: [
      new Date(new Date().getFullYear(), 9, 1),
      new Date(new Date().getFullYear(), 11, 31),
    ],
  },
  {
    label: "Last 5 Years",
    value: [new Date(new Date().getFullYear() - 5, 0, 1), new Date()],
  },
  {
    label: "All Time",
    value: [
      new Date(0), // This sets the date to the earliest possible date in JavaScript
      new Date(),
    ],
  },
]

const deliveryTypes = [
  { id:1, label: "Other Channel Delivery", value: 1, group: "Bypass"},
  { id:2, label: "On-net SIM", value: 2, group: "Bypass"},
  { id:4, label: "On-net unofficial SMSC", value: 4, group: "Bypass"},
  { id:3, label: "Off-net SIM", value: 3, group: "Bypass"},
  { id:5, label: "Off-net unofficial SMSC", value: 5, group: "Bypass"},
  { id:6, label: "International SMSC", value: 6, group: "Bypass"},
  { id:7, label: "Fake DLR", value: 7, group: "Misc."},
  { id:8, label: "Official", value: 8, group: "Misc."},


]

export default function CustomerAnalyticsBypassRoutes() {
  const { SearchBar } = Search
  const [selectedCountry, setCountry] = useState("")
  const [selectedOperator, setOperator] = useState("")
  const [selectedCustomer, setCustomer] = useState("")
  const [selectedGatekeeper, setGatekeeper] = useState("")
  const [selectedDeliveryType, setDeliveryType] = useState([1, 2, 3, 4, 5, 6])
  const [selectedXTest, setXTest] = useState("")
  const [selectedTemplate, setTemplate] = useState("")
  const sixMonthsAgo = new Date()
  const currentDate = new Date()
  sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 3)

  const formattedStartDate = sixMonthsAgo.toISOString().slice(0, 10)
  const formattedEndDate = currentDate.toISOString().slice(0, 10)

  const [startDate, setStartDate] = useState(formattedStartDate)
  const [endDate, setEndDate] = useState(formattedEndDate)
  const [smscOwnerOptions, setSmscOwnerOptions] = useState([])
  const [dateSetting, setDateSetting] = useState(false)



  const dispatch = useDispatch()

  const [sortOrder, setSortOrder] = useState("asc")

  const handleSortChange = newSortOrder => {
    setSortOrder(newSortOrder)
  }

  const { data: templatesNew, isLoading: templatesLoading } =
    useGetTemplatesQuery()
  const { templates: template } = useSelector(state => state.templateNew)

  const { data: countriesNew, isLoading: countriesLoading } =
    useGetCustomerOperatorCountriesQuery()
  const { countries: country } = useSelector(state => state.countryNew)
  

  const sortedCountries = [...country].sort((a, b) =>
    a.label.localeCompare(b.label)
  )

  const [
    fetchOperators,
    {
      data: operatorsNew,
      isLoading: operatorsLoading,
      isSuccess: operatorLoadSuccess,
    },
  ] = useLazyGetCustomerOperatorsByCountryQuery()

  // const [
  //   fetchGatekeepers,
  //   {
  //     data: gatekeepersNew,
  //     isFetching: gatekeepersLoading,
  //     isLoading: gatekeepersLoading2,
  //     isSuccess: gatekeepersLoadSuccess,
  //     isUninitialized: gatekeepersUninitialized,
  //   },
  // ] = useLazyGetGatekeepersQuery()
  const { operators: operator } = useSelector(
    state => state.operatorNew
  )

  const [filteredResults, setFilteredResults] = useState([])

  const [
    getBypassRoutes,
    {
      data: bypassRoutesData,
      isLoading: bypassRoutesLoading,
      isError: bypassRoutesError,
    },
  ] = useGetCustomerBypassRoutesMutation()
  const { bypassRoutesCount } = useSelector(state => state.analyticsSlice)

  useEffect(() => {
    if (countriesNew) {
      let data = countriesNew
      // console.log(data);
      dispatch(setCountries(data))
    }
  }, [countriesNew])


  useEffect(() => {
    if (operatorsNew && (selectedCountry)) {
      let data = operatorsNew
      dispatch(setOperators(data))
    }
  }, [operatorsNew, selectedCountry])

  // useEffect(() => {
  //   if (gatekeepersNew && !gatekeepersLoading) {
  //     let data = gatekeepersNew
  //     // console.log('triggered');
  //     dispatch(setGatekeepers(data))
  //   }
  // }, [gatekeepersNew, gatekeepersLoading])

  useEffect(() => {
    // console.log(selectedCountry, selectedCustomer);
    if (selectedCountry) {
      // console.log(selectedCountry);
      fetchOperators({
        country_id: selectedCountry,
      })
    } else {
      setOperator("")
      dispatch(setOperators([]))
    }
  }, [selectedCountry])

  // useEffect(() => {
  //   if (selectedOperator) {
  //     // console.log('triggered');
  //     dispatch(setGatekeepers([]))
  //     fetchGatekeepers({ operator_id: selectedOperator })
  //   } else {
  //     setGatekeeper("")
  //     dispatch(setGatekeepers([]))
  //   }
  // }, [selectedOperator])

  useEffect(() => {
    if (templatesNew) {
      let data = get(templatesNew, "templates", [])
      dispatch(setTemplates(data))
    }
  }, [templatesNew])
  let query = useEffect(() => {
    dispatch(setBypassRoutesCount([]))
    setSmscOwnerOptions([])
    setFilteredResults([])
    // console.log(gatekeepers,gatekeepersNew);
    if (
      selectedXTest && !dateSetting &&
      ((startDate && endDate) || (!startDate && !endDate)) &&
      (!selectedOperator ||
        (selectedOperator))
    ) {
      getBypassRoutes({
        country_id: selectedCountry,
        operator_id: selectedOperator,
        customer_id: selectedCustomer,
        deliveryType: selectedDeliveryType,
        startDate: startDate,
        endDate: endDate,
        lastXtests: selectedXTest,
        template: selectedTemplate,
      })
        .unwrap()
        .then(data => {
          let temp = get(data, "data", [])
          // console.log(temp);
          dispatch(setBypassRoutesCount(temp))
          // Create a set of unique smsc_owner values, handling null or undefined with a fallback to "Empty"
          let uniqueSmscOwners = new Set(temp.map(item => item.smsc_owner || "Empty"));

          // Convert the set to an array and then to an object with indices as keys and smsc_owner values as values
          let smscOwnerOptions = Array.from(uniqueSmscOwners).reduce((acc, value, index) => {
            // Handle empty strings or null values uniformly as "Empty"
            acc[index] = value === "" ? "Empty" : value;
            return acc;
          }, {});

          // Set the smscOwnerOptions state or use it as needed
          setSmscOwnerOptions({ smscOwner: smscOwnerOptions }); 
        })
        .catch(error => {
          toastr.error(error)
        })
    }
  }, [
    selectedCountry,
    selectedOperator,
    selectedCustomer,
    startDate,
    endDate,
    selectedGatekeeper,
    selectedXTest,
    selectedTemplate,
    selectedDeliveryType,
    dateSetting
  ])

  const columns2 = [
    // {
    //   dataField: "gate",
    //   // text: (
    //   //   <SortableHeader
    //   //     title="Gate"
    //   //     sortOrder={sortOrder}
    //   //     onSortChange={handleSortChange}
    //   //   />
    //   // ),
    //   text: "Gate",
    //   headerClasses: "country-header",
    //   sort: true,
    // },
    {
      dataField: "country",
      text: "Country",
      headerClasses: "country-header",
      sort: true,
      filter: textFilter({
        placeholder: "Enter Country",
        className: "gate-filter",
        comparator: Comparator.LIKE,
        caseSensitive: false,
        delay: 400,
      }),
    },
    {
      dataField: "operator",
      text: "Operator",
      headerClasses: "country-header",
      sort: true,
      filter: textFilter({
        placeholder: "Enter Operator",
        className: "gate-filter",
        comparator: Comparator.LIKE,
        caseSensitive: false,
        delay: 400,
      }),
    },
    {
      dataField: "deliveryType",
      text: "Delivery Type",
      headerClasses: "country-header",
      sort: true,
      formatter: cell => {
        return (
          <>
            {cell.split(",").map((item, index) => (
              <div key={index}>{item}</div>
            ))}
          </>
        )
      },
    },
    {
      dataField: "smsc",
      text: "SMSC",
      headerClasses: "country-header",
      sort: true,
      filter: textFilter({
        placeholder: "Enter SMSC",
        className: "gate-filter",
        comparator: Comparator.LIKE,
        caseSensitive: false,
        delay: 400,
      }),
    },
    // {
    //   dataField: "smsc_owner",
    //   text: "SMSC Owner",
    //   headerClasses: "country-header",
    //   sort: true,
    // },
    { 
      dataField: "smsc_owner", 
      text: "SMSC Owner",
      // sort: true,
      headerClasses: "country-header3",
      headerFormatter: (column, colIndex,{sortElement, filterElement}) => {
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>SMSC Owner</span>
            <span>
              <div>{sortElement}</div>
              <div className="tableFilter" style={{fontSize: '10px'}}>{filterElement}</div>
            </span>
          </div>
        )
      },
      editable: false,
      // filter: selectFilter({
      //   options: { ...smscOwnerOptions.smscOwner, all: "All Options" },
      //   defaultValue: "all",
      //   withoutEmptyOption: true,
      //   onFilter: (filterVal, data) => {
      //     // Check if data is valid
      //     if (!data) return [];

      //     if (filterVal === "all") {
      //       // If the filter value is 'all', return all rows
      //       return data;
      //     } else {
      //       // Ensure smscOwnerOptions and smscOwnerOptions.smscOwner are valid
      //       if (!smscOwnerOptions || !smscOwnerOptions.smscOwner) return [];

      //       if (filterVal === "Empty") {
      //         return data.filter(
      //           row => row.smsc_owner === "" || row.smsc_owner === null
      //         );
      //       } else {
      //         const value = smscOwnerOptions.smscOwner[filterVal];
      //         // Check if value is valid to avoid filtering with undefined
      //         if (value === undefined) return [];
      //         return data.filter(row => row.smsc_owner === value);
      //       }
      //     }
      //   },
      // }),
      filter: textFilter({
        placeholder: "Enter SMSC Owner",
        className: "gate-filter",
        comparator: Comparator.LIKE,
        caseSensitive: false,
        delay: 400,
      }),
    },
    {
      dataField: "count",
      text: "Count",
      headerClasses: "country-header",
      sort: true,
    },
  ]

  const handleDateChange = debounce(value => {
    if (value) {
      if (value.length > 0) {
        setDateSetting(true)
        let start = new Date(value[0])
        let end = new Date(value[1])
        if (!isNaN(start.getTime()) && !isNaN(end.getTime())) {
          setStartDate("")
          setEndDate("")
          let Startdate = start.toISOString().split("T")[0]
          let Enddate = end.toISOString().split("T")[0]
          setStartDate(Startdate)
          setEndDate(Enddate)
        } else {
          setStartDate("")
          setEndDate("")
        }
        setDateSetting(false)
      } else {
        setStartDate("")
        setEndDate("")
      }
    } else {
      setStartDate("")
      setEndDate("")
    }
  }, 300)

  useEffect(() => {
    if (bypassRoutesCount) {
      // console.log(gatekeeperFakeDlr)
      setFilteredResults(bypassRoutesCount)
    }
  }, [bypassRoutesCount])
  const CustomClearIndicator = props => {
    return (
      <div
        onClick={props.clearValue}
        style={{ cursor: "pointer", padding: "0 5px" }}
      >
        <svg
          width="14px"
          height="14px"
          style={{ marginRight: 10 }}
          color="black"
          viewBox="0 0 16 16"
          fill="currentColor"
          aria-hidden="true"
          focusable="false"
          class="rs-icon"
          aria-label="close"
          data-category="application"
        >
          <style>
            {`
              .rs-icon:hover {
                fill: red;
                border-width: '5px';
              }
            `}
          </style>
          <path d="M2.784 2.089l.069.058 5.146 5.147 5.146-5.147a.5.5 0 01.765.638l-.058.069L8.705 8l5.147 5.146a.5.5 0 01-.638.765l-.069-.058-5.146-5.147-5.146 5.147a.5.5 0 01-.765-.638l.058-.069L7.293 8 2.146 2.854a.5.5 0 01.638-.765z"></path>
        </svg>
      </div>
    )
  }

  const DropdownIndicator = props => {
    return (
      // props.selectProps.value ? null : (
      <components.DropdownIndicator {...props}>
        <svg
          height="20"
          fill="#808080"
          width="20"
          viewBox="0 0 20 20"
          aria-hidden="true"
          focusable="false"
          class=""
        >
          <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
        </svg>
      </components.DropdownIndicator>
      // )
    )
  }

  const CustomMultiValueLabel = ({ data }) => {
    let label = data.label

    if (label.length > 15) {
      label = `${label.slice(0, 7)}...${label.slice(-7)}`
    }

    return (
      <div
        style={{
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: "normal",
          fontSize: "11px",
          padding: "2px 5px",
        }}
      >
        {label}
      </div>
    )
  }
  // console.log(selectedCountry);
  return (
    <div className="page-content">
      <MetaTags>
        <title>Bypass Routes</title>
      </MetaTags>
      <div className="container-fluid">
        <Breadcrumbs title="Analytics" breadcrumbItem="Bypass Routes" />
        <Card>
          <CardBody className="custom-cardbody">
            <Row
              className="pb-4"
              style={{
                zIndex: "2",
                position: "relative",
                display: "flex",
                alignItems: "baseline",
                justifyContent: 'flex-end'
                // flexWrap: 'nowrap',
                // overflowX: 'auto'
              }}
            >
              {/* <Col md="4" className="d-flex" style={{ alignItems: "center" }}>
                <div className="search-box me-2 mb-2 pt-2 d-inline-block w-sm-100">
                  <div className="position-relative">
                  </div>
                </div>
              </Col> */}
              <Col md="" className="custom_col3">
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  isDisabled={bypassRoutesLoading}
                  isSearchable={true}
                  isClearable
                  value={sortedCountries.filter(
                    option => option.value === selectedCountry
                  )}
                  name="country"
                  // options={[
                  //   { value: "10", label: "Last 10 Tests" },
                  //   { value: "20", label: "Last 20 Tests" },
                  //   { value: "50", label: "Last 50 Tests" },
                  // ]}
                  options={[
                    { value: "", label: "No Country Selected" },
                    ...sortedCountries,
                  ]}
                  onChange={e => {
                    if (e) {
                      if (e.value) {
                        setOperator("")
                        dispatch(setOperators([]))
                        setCountry(e.value)
                      }
                      if (e.value === "") {
                        setOperator("")
                        setCountry("")
                      }
                    } else {
                      setOperator("")
                      setCountry("")
                    }
                  }}
                  placeholder="Country"
                  components={{
                    ClearIndicator: CustomClearIndicator,
                    DropdownIndicator: !selectedCountry
                      ? DropdownIndicator
                      : null,
                  }}
                />
              </Col>
              {/* <Col md="2" className="custom_col">
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  isDisabled={bypassRoutesLoading}
                  isSearchable={true}
                  isClearable
                  value={customer?.filter(
                    option => option.value === selectedCustomer
                  )}
                  name="customer"
                  // options={[
                  //   { value: "10", label: "Last 10 Tests" },
                  //   { value: "20", label: "Last 20 Tests" },
                  //   { value: "50", label: "Last 50 Tests" },
                  // ]}
                  options={[
                    { value: "", label: "No Customer Selected" },
                    ...customer,
                  ]}
                  onChange={e => {
                    if (e) {
                      if (e.value) {
                        setOperator("")
                        dispatch(setOperators([]))
                        setCustomer(e.value)
                      }
                      if (e.value === "") {
                        setOperator("")
                        setCustomer("")
                      }
                    } else {
                      setOperator("")
                      setCustomer("")
                    }
                  }}
                  placeholder="Customer"
                  components={{
                    ClearIndicator: CustomClearIndicator,
                    DropdownIndicator: !selectedCustomer
                      ? DropdownIndicator
                      : null,
                  }}
                />
              </Col> */}
              <Col md="" className="custom_col3">
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  // isDisabled={bypassRoutesLoading}
                  isSearchable={true}
                  isClearable
                  name="operator"
                  value={operator.filter(
                    option => option.id === selectedOperator
                  )}
                  isDisabled={
                    (selectedCountry == "" && selectedCustomer == "") ||
                    bypassRoutesLoading
                  }
                  // options={[
                  //   { value: "1", label: "Template 1" },
                  //   { value: "2", label: "Template 2" },
                  //   { value: "3", label: "Template 3" },
                  // ]}
                  options={[
                    { value: "", label: "No Operator Selected" },
                    ...operator,
                  ]}
                  onChange={e => {
                    if (e) {
                      if (e.id) {
                        setOperator(e.id)
                      }
                      if (e.value === "") {
                        setOperator("")
                      }
                    } else {
                      setOperator("")
                    }
                  }}
                  components={{
                    ClearIndicator: CustomClearIndicator,
                    DropdownIndicator: !selectedOperator
                      ? DropdownIndicator
                      : null,
                  }}
                  placeholder="Operator"
                />
              </Col>
              {/* <Col md="2" className="custom_col">
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  // isDisabled={bypassRoutesLoading}
                  isSearchable={true}
                  isDisabled={
                    selectedOperator === ""
                      ? true
                      : false || bypassRoutesLoading
                  }
                  isClearable
                  value={gatekeepers.filter(
                    option => option.label === selectedGatekeeper
                  )}
                  name="gates"
                  placeholder="Gate"
                  options={[
                    // { value: "", label: "No Gatekeeper Selected" },
                    ...gatekeepers,
                  ]}
                  onChange={e => {
                    if (e) {
                      if (e.label) {
                        setGatekeeper(e.label)
                      }
                      if (e.label === "") {
                        setGatekeeper("")
                      }
                    } else {
                      setGatekeeper("")
                    }
                  }}
                  components={{
                    ClearIndicator: CustomClearIndicator,
                    DropdownIndicator: !selectedGatekeeper
                      ? DropdownIndicator
                      : null,
                  }}
                />
              </Col> */}
              <Col md="" className="custom_col3">
                <Select
                  className="basic-single fixed-height"
                  classNamePrefix="select"
                  isDisabled={bypassRoutesLoading}
                  isSearchable={true}
                  isClearable
                  name="template"
                  // options={[
                  //   { value: "1", label: "Template 1" },
                  //   { value: "2", label: "Template 2" },
                  //   { value: "3", label: "Template 3" },
                  // ]}
                  options={[
                    { value: "", label: "No Template Selected" },
                    ...template,
                  ]}
                  onChange={e => {
                    if (e) {
                      if (e.label) {
                        setTemplate(e.label)
                      }
                      if (e.value === "") {
                        setTemplate("")
                      }
                    } else {
                      setTemplate("")
                    }
                  }}
                  components={{
                    ClearIndicator: CustomClearIndicator,
                    DropdownIndicator: !selectedTemplate
                      ? DropdownIndicator
                      : null,
                  }}
                  placeholder="Select Template"
                />
              </Col>
              <Col md="" className="custom_col3">
                {/* <TagPicker
                className="basic-single fixed-height tagpicker_analytics"
                menuAutoWidth
                cleanable
                data={[
                  { id:1, label: "Other Channel", value: 1, group: "Bypass"},
                  { id:2, label: "Sim On Net", value: 2, group: "Bypass"},
                  { id:3, label: "Sim Off Net", value: 3, group: "Bypass"},
                  { id:4, label: "Sim National Off Net", value: 4, group: "Bypass"},
                  { id:5, label: "No Sim National Off Net", value: 5, group: "Bypass"},
                  { id:6, label: "International", value: 6, group: "Bypass"},
                  { id:7, label: "Fake DLR", value: 7, group: "Misc."},
                  { id:8, label: "Official", value: 8, group: "Misc."},
                ]}
                value={selectedDeliveryType}
                onChange={(value) => {
                  if (value) {
                    setDeliveryType(value)
                  } else {
                    setDeliveryType([])
                  }
                }}
                groupBy="group"
                placeholder={<div style={{ color: "#8e8e93", fontSize: "13px", fontWeight: "400" }}>Delivery Type</div>}
                preventOverflow
                /> */}
                <Select
                  className="basic-single fixed-height"
                  classNamePrefix="select"
                  isDisabled={bypassRoutesLoading}
                  isSearchable={true}
                  isClearable
                  isMulti
                  placeholder="Select Delivery Type"
                  value={deliveryTypes.filter(obj =>
                    selectedDeliveryType.includes(obj.id)
                  )}
                  options={deliveryTypes}
                  styles={{
                    control: base => ({
                      ...base,
                      height: "38px",
                      minHeight: "38px",
                      maxHeight: "38px",
                    }),
                    multiValue: base => ({
                      ...base,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      maxWidth: "100%",
                      maxHeight: "38px",
                      zIndex: 1,
                    }),
                    valueContainer: base => ({
                      ...base,
                      overflow: "auto",
                      // height: '38px',
                      // minHeight: '34px',
                      maxHeight: "34px",
                    }),
                  }}
                  components={{
                    ClearIndicator: CustomClearIndicator,
                    DropdownIndicator:
                      selectedDeliveryType.length == 0
                        ? DropdownIndicator
                        : null,
                    MultiValueLabel: CustomMultiValueLabel,
                  }}
                  onChange={e => {
                    if (e) {
                      if (e.length > 0) {
                        setDeliveryType(e.map(obj => obj.id))
                      } else {
                        setDeliveryType([])
                      }
                    } else {
                      setDeliveryType([])
                    }
                  }}
                />
              </Col>
              <Col md="" className="custom_col3">
                <Select
                  className="basic-single fixed-height"
                  isDisabled={bypassRoutesLoading}
                  classNamePrefix="select"
                  isSearchable={true}
                  isClearable
                  name="xTest"
                  placeholder="Last X Test"
                  options={[
                    { value: "10", label: "Last 10 Tests" },
                    { value: "20", label: "Last 20 Tests" },
                    { value: "50", label: "Last 50 Tests" },
                    { value: "100", label: "Last 100 Tests" },
                    { value: "1000", label: "Last 1000 Tests" },
                  ]}
                  components={{
                    ClearIndicator: CustomClearIndicator,
                    DropdownIndicator: !selectedXTest
                      ? DropdownIndicator
                      : null,
                  }}
                  onChange={e => {
                    if (e) {
                      if (e.value) {
                        setXTest(e.value)
                      }
                      if (e.value === "") {
                        setXTest("")
                      }
                    } else {
                      setXTest("")
                    }
                  }}
                />
              </Col>
              <Col md="" className="custom_col3">
                <div style={{ width: "100%" }}>
                  <DateRangePicker
                    disabled={bypassRoutesLoading}
                    cleanable={!bypassRoutesLoading}
                    ranges={predefinedBottomRanges}
                    className="basic-single "
                    placeholder="Select Date Range"
                    style={{ height: "38px", width: "100%" }}
                    placement="autoVerticalEnd"
                    onChange={handleDateChange}
                    defaultValue={[sixMonthsAgo, new Date()]}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="table-responsive scrollable table_shadow_div reportdata_table2">
                  <BootstrapTable
                    keyField="id"
                    data={filteredResults}
                    columns={columns2}
                    bootstrap4={true}
                    filter={filterFactory()}
                    noDataIndication={() => {
                      if (bypassRoutesLoading === true) {
                        return (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <LoadingSpinner />
                          </div>
                        )
                      } else {
                        return <div>No Data Found</div>
                      }
                    }}
                  />
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </div>
  )
}

import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Button, Table, Input,  Modal, ModalFooter,  Container } from "reactstrap"
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux"
import { getNewCountry } from "store/old/partner/actions";

const AddPartner = (props) => {

const setPartnerName=props.set_partner_name;
const setPartnerTemplate=props.set_partner_template;


const [selectedMulti, setselectedMulti] = useState(null);
const dispatch = useDispatch();
  
function handleMulti(selectedMulti) {
    setselectedMulti(selectedMulti);
}

const optionGroup = [
    { label: "Mustard", value: "Mustard" },
    { label: "Ketchup", value: "Ketchup" },
    { label: "Relish", value: "Relish" },
    { label: "Tent", value: "Tent" },
    { label: "Flashlight", value: "Flashlight" },
    { label: "Toilet Paper", value: "Toilet Paper" },
  ];

  // useEffect(() => {
  // }, [ selectedTemplate])

useEffect(() => {

    dispatch(getNewCountry())
  
}, [])

// const countries = useSelector((state) => state.partner.countries);
const countryData = [
  { id:0,value: "", label: 'Select Template'},
  { id:1,value: '1', label: 'Horisen' },
  { id:2,value: '2', label: 'Alaris' },
]

return (
    <React.Fragment>
        <form>
                      <div className="customer-field-container" >
                        <Row>
                          <Col md="4">
                          <label className="lable-customer">Partner Name</label>
                          </Col>
                          <Col md="8">
                          <Input
                            type="name"
                            className="input-operator"
                            placeholder="Enter the Name"
                            onChange={(e)=>{
                              setPartnerName(e.target.value);
                            }}
                          />
                          </Col>
                        </Row>
                        <Row>
                          <Col md="4">
                          <label className="lable-customer">Data Template</label>
                          </Col>
                          <Col md="8">
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            isSearchable={true}
                            name="template"
                            options={countryData}
                            placeholder="Template"
                            onChange={e => {
                              setPartnerTemplate(e.id)
                            }}
                          />
                          {/* <Select
                            className="customer-mult-input"
                            //isSearchable={true}
                            name="country"
                            options={countryData}
                            placeholder="Select Template"
                            onChange={(e)=>{
                              setPartnerTemplate(e.value)
                            }}
                            
                          /> */}
                          </Col>
                          
                        </Row>
                        
                      </div>
                     
                    

                      
                    </form>
    </React.Fragment>
  )
}

export default AddPartner
